<template>
  <!-- Begin main content -->
  <div
    class="main-content comming-soon section-bg lazy"
    style="background-image: url('/img/template/main-banner-img1.jpg');"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-page-title page-title-center">
            <h1 class="page-title">We’re getting ready to launch in</h1>
          </div>
          <Countdown :date="'2022/04/01'" />
        </div>
      </div>
    </div>
  </div>
  <!-- End main content -->
</template>

<script>
import Countdown from "@/public/components/Countdown.vue";

export default {
  components: {
    Countdown
  }
};
</script>

<style>
.main-content .container{
  min-height:710px;
}
</style>