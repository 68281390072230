<template>
  <div v-if="days>0 && hours>0 && minutes>0 && seconds>0" class="wrapp-countdown">
    <div id="countdown" class="countdown">
      <div class="countdown-number item-style">
        <span class="days countdown-time">{{ days | two_digits }}</span>
        <span class="countdown-text">Days</span>
      </div>
      <div class="countdown-number item-style">
        <span class="hours countdown-time">{{ hours | two_digits }}</span>
        <span class="countdown-text">Hours</span>
      </div>
      <div class="countdown-number item-style">
        <span class="minutes countdown-time">{{ minutes | two_digits }}</span>
        <span class="countdown-text">Minutes</span>
      </div>
      <div class="countdown-number item-style">
        <span class="seconds countdown-time">{{ seconds | two_digits }}</span>
        <span class="countdown-text">Seconds</span>
      </div>
    </div>
    <div id="deadline-message" class="deadline-message">Time is up!</div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  props: {
    date: {
      type: String
    }
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000)
    };
  },
  computed: {
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000);
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    }
  }
};
</script>

<style>
</style>